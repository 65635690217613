@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100&display=swap);
body {
  margin: 0;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home,
.about,
.projects,
.contact {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.about {
 /* background-image: url('../public/images/img-2.jpg');*/
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.projects {
  /*background-image: url('../public/images/img-1.jpg');*/
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.contact {
  /*background-image: url('../public/images/img-8.jpg');*/
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}
:root{
    --primary: #fff;
}

.btn{
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
}


.btn--primary{
    background-color: #fff;
    background-color: var(--primary);
    color: #242424;
    border: 1px solid #fff;
    border: 1px solid var(--primary);
}

.btn--outline {
    background-color: transparent;
    color: #fff;
    padding: 8px 26px;
    border: 1px solid #fff;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
    margin-left:10px;
}

.btn-medium {
    padding: 8px 20px;
    font-size: 20px;
}

.btn--large{
    padding: 12px 26px;
    font-size: 20px;
    width: 600px;
}

.btn--medium:hover, .btn--large:hover{
    background: white;
    color: #242424;
    transition: all 0.3s ease-out;
}


video{
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
}


.background-container{
    /*background: url('images/img-home.jpg') center center/cover no-repeat;*/
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.2);
    object-fit: contain;
}


.background-container > h1 {
    color: white;
    font-size:  100px;
    margin-top: -100px;
    font-family: 'Montserrat', sans-serif;
}

.background-container > p {
    margin-top:  8px;
    color: white;
    font-size: 32px;
    font-family: 'Montserrat', sans-serif;
}

.typewriter {
    color: white;
    font-size:  25px;
    font-family: 'Montserrat', sans-serif;
    margin-top: 0px;
}


.background-btns {
    margin-top: 32px;
}

.background-btns .btn {
    margin:  6px;
}

.fa-play-circle {
    margin-left:  4px;
}

@media screen and (max-width : 960px){
    .background-container > h1 {
        color: white;
        font-size:  70px;
        margin-top: -150px;
    }
}

@media screen and (max-width : 768px){
    .background-container > h1 {
        color: white;
        font-size:  50px;
        margin-top: -100px;
    }

    .background-container > p {
        font-size:  30px;
    }

    .btn-mobile {
        display: block;
        text-decoration: none;
    }

    .btn {
        width: 100%;
    }
}


.cards {
    padding: 4rem;
    background: rgb(243, 243, 243);
    font-family: 'Montserrat', sans-serif;
  }
  
  h1 {
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size:  45px;
  }
  
  .cards__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 90%;
    margin: 0 auto;
  }
  
  .cards__wrapper {
    position: relative;
    margin: 50px 0 45px;
  }
  
  .cards__items {
    margin-bottom: 24px;
  }
  
  .cards__item {
    display: flex;
    flex: 1 1;
    margin: 0 1rem;
    border-radius: 10px;
  }
  
  .cards__item__link {
    display: flex;
    flex-flow: column;
    width: 100%;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
  }
  
  .cards__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
  }
  
  .fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
  }
  
  .cards__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    background-color: #1f98f4;
    box-sizing: border-box;
  }
  
  .cards__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
  }
  
  .cards__item__img:hover {
    transform: scale(1.1);
  }
  
  .cards__item__info {
    padding: 20px 30px 30px;
  }
  
  .cards__item__text {
    color: #252e48;
    font-size: 14px;
    line-height: 24px;
  }
  
  @media only screen and (min-width: 1200px) {
    .content__blog__container {
      width: 84%;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    .cards__items {
      display: flex;
    }
  }
  
  @media only screen and (max-width: 1024px) {
    .cards__item {
      margin-bottom: 2rem;
      scale: 75%;

    }
  }
.footer-container {
    background-color: #242424;
    padding: 4rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .footer-subscription {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  
    margin-bottom: 24px;
    padding: 24px;
    color: #fff;
  }
  
  .footer-subscription > p {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  .footer-subscription-heading {
    margin-bottom: 24px;
    font-size: 24px;
  }
  
  .footer-subscription-text {
    margin-bottom: 24px;
    font-size: 20px;
  }
  
 
  .footer-input::-webkit-input-placeholder {
    color: #b1b1b1;
  }
  
 
  .footer-input:-ms-input-placeholder {
    color: #b1b1b1;
  }
  
 
  .footer-input::-ms-input-placeholder {
    color: #b1b1b1;
  }
  
 
  .footer-input::placeholder {
    color: #b1b1b1;
  }
  
  /* Social Icons */
  .social-icon-link {
    color: #fff;
    font-size: 24px;
  }
  
  .social-media {
    max-width: 1000px;
    width: 100%;
  }
  
  .social-media-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1000px;
    margin: 40px auto 0 auto;
  }
  
  
  .social-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .website-rights {
    color: #fff;
    margin-bottom: 16px;
  }
  
  @media screen and (max-width: 820px) {
    .footer-links {
      padding-top: 2rem;
    }
  
    .footer-input {
      width: 100%;
    }
  
    .btn {
      width: 100%;
    }
  
    .footer-link-wrapper {
      flex-direction: column;
    }
  
    .social-media-wrap {
      flex-direction: column;
    }
  }
  
  @media screen and (max-width: 768px) {
  }
.about {
  padding: 4rem;
  background: rgb(255, 255, 255);
  font-family: "Montserrat", sans-serif;
  max-height: 800px;
}

.about-container {
  width: 50%;
}

.about-container > h1 {
  color: black;
  text-align: left;
  font-size: 45px;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 50px;
}

.about-text-container > h2 {
  color: black;
  font-size: 25px;
  font-family: "Montserrat", sans-serif;
  margin-left: 400px;
  width: 50%;
}

.about-image {
  float: left;
}

@media screen and (max-width: 960px) {
  .about-container {
    width: 100%;
  }
 
}


@media screen and (max-width: 960px) {
  .about-container > h1 {
    color: rgb(0, 0, 0);
    font-size: 40px;
    text-align: center;
  }
}


@media screen and (max-width: 960px) {
  .about-text-container > h2 {
    color: rgb(0, 0, 0);
    font-size: 20px;
    display: block;
    margin-left: 25px;
    margin-top: 50px;
    width: 100%;
  }

}

.resume {
    padding: 4rem;
    background: rgb(255, 255, 255);
    font-family: "Montserrat", sans-serif;
  }


  h1 {
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size:  45px;
  }


  .resume-container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 100%;
    margin: 0 auto;
  }
  
  .resume-wrapper {
    position: relative;
    margin: 50px 0 45px;
  }


  .resume-items {
    margin-bottom: 24px;
  }
  
  .resume-item {
    display: flex;
    flex: 2 1;
    margin: 0 1rem;
    border-radius: 10px;
  }

  @media screen and (max-width : 768px){
    .resume-item{
      scale: 70%;
    }
    .resume-item2{
      scale: 70%;
    }
  }
.navbar {
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 999;
  }
  
  .navbar-container {
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    max-width: 1500px;
  }
  
  .navbar-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
  }
  
  .fa-typo3 {
    margin-left: 0.5rem;
    font-size: 1.8rem;
  }
  
  .nav-menu {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: end;
    margin-right: 2rem;
  }
  
  .nav-item {
    height: 80px;
  }
  
  .nav-links {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
  }
  
  .nav-links:hover {
    border-bottom: 4px solid #fff;
    transition: all 0.2s ease-out;
  }
  
  .fa-bars {
    color: #fff;
  }
  
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
  }
  
  @media screen and (max-width: 960px) {
    .NavbarItems {
      position: relative;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 90vh;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: #242222;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }
  
    .nav-links:hover {
      background-color: #fff;
      color: #242424;
      border-radius: 0;
    }
  
    .navbar-logo {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(25%, 50%);
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }
  
    .nav-links-mobile {
      display: block;
      text-align: center;
      margin: 2rem auto;
      border-radius: 4px;
      width: 80%;
      text-decoration: none;
      font-size: 1.5rem;
      background-color: transparent;
      color: #fff;
      padding: 14px 20px;
      border: 1px solid #fff;
      transition: all 0.3s ease-out;
    }
  
    .nav-links-mobile:hover {
      background: #fff;
      color: #242424;
      transition: 250ms;
    }
  }
