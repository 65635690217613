@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100&display=swap');

video{
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
}


.background-container{
    /*background: url('images/img-home.jpg') center center/cover no-repeat;*/
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.2);
    object-fit: contain;
}


.background-container > h1 {
    color: white;
    font-size:  100px;
    margin-top: -100px;
    font-family: 'Montserrat', sans-serif;
}

.background-container > p {
    margin-top:  8px;
    color: white;
    font-size: 32px;
    font-family: 'Montserrat', sans-serif;
}

.typewriter {
    color: white;
    font-size:  25px;
    font-family: 'Montserrat', sans-serif;
    margin-top: 0px;
}


.background-btns {
    margin-top: 32px;
}

.background-btns .btn {
    margin:  6px;
}

.fa-play-circle {
    margin-left:  4px;
}

@media screen and (max-width : 960px){
    .background-container > h1 {
        color: white;
        font-size:  70px;
        margin-top: -150px;
    }
}

@media screen and (max-width : 768px){
    .background-container > h1 {
        color: white;
        font-size:  50px;
        margin-top: -100px;
    }

    .background-container > p {
        font-size:  30px;
    }

    .btn-mobile {
        display: block;
        text-decoration: none;
    }

    .btn {
        width: 100%;
    }
}

