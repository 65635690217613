@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100&display=swap");

.about {
  padding: 4rem;
  background: rgb(255, 255, 255);
  font-family: "Montserrat", sans-serif;
  max-height: 800px;
}

.about-container {
  width: 50%;
}

.about-container > h1 {
  color: black;
  text-align: left;
  font-size: 45px;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 50px;
}

.about-text-container > h2 {
  color: black;
  font-size: 25px;
  font-family: "Montserrat", sans-serif;
  margin-left: 400px;
  width: 50%;
}

.about-image {
  float: left;
}

@media screen and (max-width: 960px) {
  .about-container {
    width: 100%;
  }
 
}


@media screen and (max-width: 960px) {
  .about-container > h1 {
    color: rgb(0, 0, 0);
    font-size: 40px;
    text-align: center;
  }
}


@media screen and (max-width: 960px) {
  .about-text-container > h2 {
    color: rgb(0, 0, 0);
    font-size: 20px;
    display: block;
    margin-left: 25px;
    margin-top: 50px;
    width: 100%;
  }

}
